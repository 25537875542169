<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4"
      ><download-excel
        class="btn btn-default"
        :data="list1"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="ข้อมูลสรุปการแจ้งเบาะแส.xls"
        :header="'ข้อมูลสรุปการแจ้งเบาะแส'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        Download ข้อมูลสรุปการแจ้งเบาะแส
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ["list1"],
  data() {
    return {
      // json_fields: {
      //   ลำดับ: "count",
      //   // ข้อเสนอแนะ: "answer",
      //   "ชื่อสถานี": "station",
      //   "จังหวัด": "province",
      //   ภาค: "area",
      //   จำนวนทั้งหมด: "sumAmt",
      //   ดำเนินการแล้ว: "statusDone",
      //   ยังไม่ได้ดำเนินการ: "statusWaiting",
      // },

      json_fields: {
        // ลำดับ: "count",
        "วันที่บันทึก": "createdAt",
        "หมายเลขแจ้งเหตุ": "runningNo",
        "ชื่อ": "name",
        "จังหวัด": "province",
        "ชื่อสถานี": "station",
        "ชื่อชุมชน": "village",
        "สถานะ": "status",
        "ลักษณะการทำความผิด": "typeWrong",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/drugrelatedReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    //   this.list1.count = parseInt(i) + 1;
    // },
  },
};
</script>
