<template>
  <v-card class="pa-6">
    <loading :active.sync="loading"></loading>
    <h2 class="headtitle">ค้นหาข้อมูลเบาะแสยาเสพติด</h2>
    <v-row v-if="opendiv199">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          @change="onChangeArea()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          @change="onChangeProvince()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="1" sm="6" xs="12">
        <v-row align="center" justify="center"
          ><v-btn width="80px" class="mt-4 mb-4" @click="clear()"
            >เคลียร์</v-btn
          ></v-row
        >
      </v-col>
    </v-row>
    <v-row v-if="opendiv2">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          @change="onChangeProvince()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="1" sm="6" xs="12">
        <v-row align="center" justify="center"
          ><v-btn width="80px" class="mt-4 mb-4" @click="clear()"
            >เคลียร์</v-btn
          ></v-row
        >
      </v-col>
    </v-row>
    <v-row v-if="opendiv3">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="1" sm="6" xs="12">
        <v-row align="center" justify="center"
          ><v-btn width="80px" class="mt-4 mb-4" @click="clear()"
            >เคลียร์</v-btn
          ></v-row
        >
      </v-col>
    </v-row>
    <v-row v-if="opendiv4">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
          disabled
        ></v-autocomplete>
      </v-col>
      <!-- <v-col cols="12" md="1" sm="6" xs="12">
          <v-row align="center" justify="center"
            ><v-btn width="80px" class="mt-4 mb-4" @click="clear()"
              >เคลียร์</v-btn
            ></v-row
          >
        </v-col> -->
    </v-row>

    <v-col cols="12" md="12" sm="12" xs="12" v-if="!opendiv4">
      <v-row align="center" justify="center">
        <v-btn
          width="80px"
          class="mt-2 mb-4"
          @click="searchDrugclues()"
          color="#833133"
          dark
          >ค้นหา</v-btn
        >
      </v-row>
    </v-col>
    <v-row class="my-4">
        <ExportManageDrugClues :list1="listdrugclues"/>
      </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="6" xs="12">
        <v-text-field
          class="mb-4"
          v-model="search"
          dense
          hide-details
          outlined
          placeholder="ค้นหา"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-col cols="12" md="4" sm="12" class="mr-0 mt-2 text-right">
        <v-chip>จำนวนเบาะแสทั้งหมด : {{ listdrugclues.length }} คน</v-chip>
      </v-col>

      <!-- <v-btn
          class="mr-4 mt-4"
          color="#833133"
          style="color: white"
          @click="createForm()"
          ><v-icon left dark> mdi-plus-circle </v-icon>เพิ่ม
        </v-btn> -->
    </v-row>
    <v-data-table
      :headers="headersdrugclues"
      :items="listdrugclues"
      :search="search"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:[`item.count`]="{ index }">
        <!-- <tr> -->
        <span>{{ index + 1 }}</span>
        <!-- </tr> -->
      </template>
      <!-- <template v-slot:[`item.address`]="{ item }">
          <p>
            บ้านเลขที่ {{ item.houseNo }} ชื่อหมู่บ้าน{{ item.village }} หมู่{{
              item.moo
            }}
            จังหวัด{{ province }}
          </p>
        </template> -->
      <template v-slot:[`item.status`]="{ item }">
        <v-chip color="light-blue" dense dark>{{ item.status }}</v-chip>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <span color="light-blue" dense dark>{{
          convertDate(item.createdAt)
        }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-row justify="center">
          <v-icon @click="viewStatus(item)" class="mr-2">mdi-eye</v-icon>
          <v-icon @click="updateStatus(item)" class="mr-2">mdi-pencil</v-icon>
          <v-icon @click="DeleteForm(item)">mdi-delete</v-icon>
          <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
        </v-row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { Decode, Encode } from "@/services";
import moment from "moment";
import ExportManageDrugClues from "@/views/ExportExcel/ExportManageDrugClues";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: { ExportManageDrugClues, Loading },
  data() {
    return {
      loading: false,
      search: "",
      listdrugclues: [],
      count: 0,
      runningNo: "",
      headersdrugclues: [
        { text: "ลำดับ", value: "count", align: "center", width: "100px" },
        {
          text: "วันที่บันทึก",
          value: "createdAt",
          align: "center",
          width: "180px",
        },
        {
          text: "หมายเลขแจ้งเหตุ",
          value: "runningNo",
          align: "center",
          sortBy: "runningNo",
          width: "180px",
        },
        { text: "ชื่อ", value: "name", align: "center", width: "250px" },
        // { text: "คำถาม", value: "question", align: "center", width: "250px" },
        { text: "จังหวัด", value: "province", align: "center", width: "180px" },
        {
          text: "ชื่อสถานี",
          value: "station",
          align: "center",
          width: "180px",
        },
        {
          text: "ชื่อชุมชน",
          value: "village",
          align: "center",
          width: "180px",
        },
        { text: "สถานะ", value: "status", align: "center", width: "200px" },
        { text: "ลักษณะการทำความผิด", value: "typeWrong", align: "center", width: "200px" },
        { text: "ตัวเลือก", value: "action", align: "center", width: "180px" },
      ],
      area: "",
      province: "",
      station: "",
      status: "",
      // statusItems: [
      //   { text: "รายงานตัว/หยุดพฤติการณ์", value: "รายงานตัว/หยุดพฤติการณ์" },
      //   { text: "หลบหนีออกนอกพื้นที่", value: "หลบหนีออกนอกพื้นที่" },
      //   { text: "อยู่ระหว่างสืบสวนติดตาม", value: "อยู่ระหว่างสืบสวนติดตาม" },
      //   { text: "อื่นๆ", value: "อื่นๆ" },
      // ],
      opendiv199: false,
      opendiv2: false,
      opendiv3: false,
      opendiv4: false,
      items: [],
      items2: [
        { text: "ทั่วประเทศ", group: "" },
        { text: "นครบาล", group: "น." },
        { text: "ภาค1", group: "ภ.1" },
        { text: "ภาค2", group: "ภ.2" },
        { text: "ภาค3", group: "ภ.3" },
        { text: "ภาค4", group: "ภ.4" },
        { text: "ภาค5", group: "ภ.5" },
        { text: "ภาค6", group: "ภ.6" },
        { text: "ภาค7", group: "ภ.7" },
        { text: "ภาค8", group: "ภ.8" },
        { text: "ภาค9", group: "ภ.9" },
      ],
      items3: [],
      items4: [],
      data: {
        area: "",
        province: "",
        station: "",
      },
      checklogin: "",
    };
  },
  created() {
    this.getdrugclues();
    // this.searchDropdown();
    // this.getREport();
  },
  methods: {
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    // async getREport() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/drugrelatedReport`
    //   );
    //   console.log("data", response.data.data);
    // },
    async onChangeArea() {
      this.items3 = [];
      this.items4 = [];
      this.data.province = "";
      this.data.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?group=` + this.data.area
      );
      this.items3 = response.data.data;
    },
    async onChangeProvince() {
      this.items4 = [];
      this.data.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?province=` +
          this.data.province
      );
      this.items4 = response.data.data;
    },
    updateStatus(val) {
      localStorage.setItem("DrugClues", Encode.encode(val));
      // this.$router.go("EditDrugClues");
      let routeData = this.$router.resolve({
        name: "EditDrugClues",
      });
      window.open(routeData.href, "_blank");
    },
    viewStatus(val) {
      localStorage.setItem("DrugClues", Encode.encode(val));
      // this.$router.go("EditDrugClues");
      let routeData = this.$router.resolve({
        name: "ViewDrugClues",
      });
      window.open(routeData.href, "_blank");
    },
    clear() {
      if (this.checklogin.userType == "1" || this.checklogin.userType == "99") {
        this.data = {
          area: "",
          province: "",
          station: "",
        };
        this.getdrugclues();
      } else if (this.checklogin.userType == "2") {
        this.data = {
          area: this.checklogin.area,
          province: "",
          station: "",
        };
        this.getdrugclues();
      } else if (this.checklogin.userType == "3") {
        this.data = {
          area: "",
          province: this.checklogin.province,
          station: "",
        };
        this.getdrugclues();
      } else if (this.checklogin.userType == "4") {
        this.data = {
          area: "",
          province: "",
          station: this.checklogin.station,
        };
        this.getdrugclues();
        // }
      }
    },
    async getdrugclues() {
      this.loading = true;
      this.checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
      console.log(this.checklogin);
      // var data;
      if (this.checklogin.userType == "1" || this.checklogin.userType == "99") {
        // data = {
        //   area: "",
        //   province: "",
        //   station: "",
        // };
        // data = this.data;
        this.opendiv199 = true;
      } else if (this.checklogin.userType == "2") {
        this.data = {
          area: this.checklogin.area || "",
          province: "",
          station: "",
        };
        this.onChangeArea();
        this.opendiv2 = true;
      } else if (this.checklogin.userType == "3") {
        this.data = {
          area: this.checklogin.area || "",
          province: this.checklogin.province || "",
          station: "",
        };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?group=` +
            this.data.area
        );
        this.items3 = response.data.data;
        this.onChangeProvince();

        console.log(this.data);
        this.opendiv3 = true;
      } else if (this.checklogin.userType == "4") {
        this.data = {
          area: this.checklogin.area || "",
          province: this.checklogin.province || "",
          station: this.checklogin.station || "",
        };

        const response1 = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?group=` +
            this.data.area
        );
        this.items3 = response1.data.data;

        const response2 = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?province=` +
            this.data.province
        );
        this.items4 = response2.data.data;
        this.opendiv4 = true;
      }
      console.log("data ที่ส่งไป", this.data);
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/drugclues/findAll`,
        this.data
      );
      // console.log("response1", response);
      console.log("drugclues/findAll", response.data.data);
      this.listdrugclues = response.data.data;
      // for (let i in this.listdrugclues) {
      //   this.listdrugclues[i].count = parseInt(i) + 1;
      //   // console.log("listdrugclues", this.listdrugclues);
      // }
      this.listdrugclues.forEach((element) => {
        element.createdAt = moment(String(element.createdAt)).format(
          "MM/DD/YYYY hh:mm"
        );
      });
      this.loading = false;
    },
    async searchDrugclues() {
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/drugclues/findAll`,
        this.data
      );
      console.log("response1", response);
      console.log("response", response.data.data);
      this.listdrugclues = response.data.data;
      for (let i in this.listdrugclues) {
        this.listdrugclues[i].count = parseInt(i) + 1;
        // console.log("listdrugclues", this.listdrugclues);
      }
    },
    async DeleteForm(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/drugclues/delete/` + val.id
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          await this.getdrugclues();
        }
      });
    },
    // UpdateForm(val) {
    //   localStorage.setItem("data", Encode.encode(val));
    //   this.$router.push("EditFormSheet3");
    // },
    async searchDropdown() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);

      this.items2 = Array.from(
        new Set(this.items.map((a) => a.group.trim()))
      ).map((group) => {
        return this.items.find((a) => a.group.trim() === group.trim());
      });

      // this.items4.forEach((item) => {
      //   item.searchText = item.station + " " + item.village;
      // });
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
